import(/* webpackMode: "eager", webpackExports: ["BackToTop"] */ "/app/apps/frontend/src/components/BackToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Chatbot"] */ "/app/apps/frontend/src/components/Chatbot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DisableRightClick"] */ "/app/apps/frontend/src/components/DisableRightClick.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavouritesLocalStorageProvider"] */ "/app/apps/frontend/src/components/Favourites/FavouritesLocalStorageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalAppProvider"] */ "/app/apps/frontend/src/components/GlobalAppContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderBarBackground"] */ "/app/apps/frontend/src/components/HeaderBar/HeaderBarBackground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderBarCarrier"] */ "/app/apps/frontend/src/components/HeaderBar/HeaderBarCarrier.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/app/apps/frontend/src/components/MainNavigation/MobileNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationItems"] */ "/app/apps/frontend/src/components/MainNavigation/NavigationItems.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.21_@playwright+test@1.48.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.21_@playwright+test@1.48.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.21_@playwright+test@1.48.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-maison-neue\",\"src\":[{\"path\":\"./maison-neue.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"maisonNew\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.21_@playwright+test@1.48.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-acumin-pro\",\"src\":[{\"path\":\"./acumin/8f7aa791-9b7e-4367-8773-56669b1f166a.woff2\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"acuminPro\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.21_@playwright+test@1.48.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-domain-display\",\"src\":\"./domaine-display-semibold.woff2\",\"weight\":\"600\"}],\"variableName\":\"domainDisplay\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/styles/global.css");
